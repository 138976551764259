'use client';

import { JobDetail } from '@components/templates/job/detail/JobDetail';
import { useSwrJobDetail, useSwrJobUserDetail } from '@hooks/useSwrApi/endpoints/job-operations';
import Head from 'next/head';
import { getJobTitle } from '@utils/job/getJobTitle';
import { useTranslation } from 'next-i18next';
import { useSwrSimilarJobsInfinite } from '@hooks/useSwrApi/endpoints/job';
import { useAppSelector } from 'src/app';
import { getUserHeaderInfo } from '@store/reducers/userSelector';
import { useContext, useEffect, useRef } from 'react';
import { useSwipeNav } from '@hooks/common/useSwipeNav';
import { JobListsContext } from '@components/contexts/JobListsContext';
import { useInfinitePrevNext } from '@hooks/infinite-helpers/useInfinitePrevNext';
import { useArrowKeys } from '@hooks/common/useArrowKeys';
import { buildJobDetailUrlParams } from '@utils/job';
import { getI18nLinkHref } from '@services/i18nPaths';
import { JobTrackingContext } from '@components/contexts/JobTrackingContext';
import { JOB_DETAIL_SIMILAR_JOBS_LIMIT } from '../../../constants';

// Interface
// ************************************
export interface JobDetailHubProps {
  locale: Locale,
  jobSlug?: string,
  closeModal?: () => void,
}

/**
 * @description Job Detail Hub. Used for rendering a job with client side rendering (ie - modal).
 * - **locale** - Locale - The current app locale.
 * - **jobSlug** - string - The job slug, optional.
 * - **closeModal** - function - Function to close the modal, optional.
 */
export const JobDetailHub = (props: JobDetailHubProps) => {
  // Destructure props
  const {
    locale,
    jobSlug,
    closeModal,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Get header info data
  // *************************************
  const headerInfoData = useAppSelector(getUserHeaderInfo);

  // SWR: Job Detail
  // ************************************
  const {
    data: jobDetail, isLoading: isLoadingJobDetail,
  } = useSwrJobDetail(jobSlug ?? '', locale);

  // SWR: Job User Detail
  // If the user is authenticated, then we need the job user detail in order to get the liked, disliked, applied props and skills matching.
  // ************************************
  const {
    data: jobUserDetail, mutate,
  } = useSwrJobUserDetail(jobSlug ?? '', !!headerInfoData, locale);

  // SWR: Similar jobs
  // ************************************
  const {
    data: similarJobs,
  } = useSwrSimilarJobsInfinite({ locale, limit: JOB_DETAIL_SIMILAR_JOBS_LIMIT, jobSlug });

  // Get context data
  // **************************************
  const {
    swrJobList,
    showLoadMore,
    size,
    setSize,
  } = useContext(JobListsContext) || {};

  // Get the prev/next jobs if available
  // **************************************
  const {
    prevItem,
    nextItem,
    lastInList, currentItemIndex,
  } = useInfinitePrevNext<ApiSchema<'JobItem'>>({
    slug: jobSlug || '',
    data: swrJobList,
    itemsFlatKey: 'items',
  });

  // Load more jobs if we are at the end of the list but there are more pages to load.
  // **************************************
  const listIsLoading = useRef(false);

  useEffect(() => {
    if (!lastInList) {
      listIsLoading.current = false;
    }
  }, [lastInList]);

  useEffect(() => {
    if (lastInList && !listIsLoading.current && showLoadMore && size && setSize) {
      listIsLoading.current = true;
      void setSize(size + 1);
    }
  }, [lastInList, showLoadMore, size, setSize, listIsLoading]);

  // Get tracking context data
  // **************************************
  const {
    requestId,
    searchId,
  } = useContext(JobTrackingContext) || {};

  // Navigate to job (modal)
  // **************************************
  const navigateToJob = (nextJobSlug: string, direction: 'prev' | 'next') => {
    const currentPosition = (currentItemIndex ?? 0) + 1;

    // Build search url params to be attached to the job url
    const urlSearchParams = buildJobDetailUrlParams({
      requestId,
      position: direction === 'prev' ? (currentPosition - 1) : (currentPosition + 1),
      searchId,
    });

    const jobUrl = getI18nLinkHref(`/job/${nextJobSlug}`, locale);

    // Build the params object from existing searchParams
    const params = new URLSearchParams(urlSearchParams?.toString());

    // Set the selectedJobSlug parameter
    params.set('selectedJobSlug', nextJobSlug);

    // Construct the new URL with proper query parameters
    const newUrl = `${jobUrl}${jobUrl.includes('?') ? '&' : '?'}${params.toString()}`;

    window.history.replaceState(null, '', newUrl);
  };

  // Swipe navigation
  // ************************************
  const swipeContainerRef = useRef(null);

  const handlePrev = () => {
    if (prevItem?.slug) {
      navigateToJob(prevItem.slug, 'prev');
    }
  };

  const handleNext = () => {
    if (nextItem?.slug) {
      navigateToJob(nextItem.slug, 'next');
    }
  };

  const { moveX } = useSwipeNav({
    swipeContainer: swipeContainerRef,
    onPrev: handlePrev,
    onNext: handleNext,
  });

  // Keyboard navigation
  // ************************************
  // If multiple modals are open (eg: Estimated Salary Modal / Gallery Modal), the navigation should be disabled.
  // HeadlessUI renders modals within a container element marked with the data-headlessui-portal attribute,
  // so we need to check if there is more than one container rendered in page.
  const isDialogOpen = () => document.querySelectorAll('div[data-headlessui-portal]').length > 1;

  // Left arrow handler - Go to prev item
  const handleLeftArrow = (): void => {
    if (!isDialogOpen() && prevItem?.slug) {
      navigateToJob(prevItem.slug, 'prev');
    }
  };

  // Right arrow handler - Go to next item
  const handleRightArrow = (): void => {
    if (!isDialogOpen() && nextItem?.slug) {
      navigateToJob(nextItem.slug, 'next');
    }
  };

  // Use arrow keys hook
  useArrowKeys({
    onLeft: handleLeftArrow,
    onRight: handleRightArrow,
  });

  // Don't render if jobSlug is undefined
  // ************************************
  if (!jobSlug) return null;

  // Render component
  // ************************************

  return (
    <>
      {/* Update title when loaded in modal */
        !!closeModal && jobDetail && (
          <Head>
            <title>{ getJobTitle(jobDetail, t) }</title>
          </Head>
        )
      }
      <div className="relative" ref={swipeContainerRef}>
        <div
          style={{
            display: 'flex',
            transform: moveX !== undefined ? `translateX(${moveX}px)` : undefined,
            transition: moveX === 0 ? 'transform 0.3s ease' : 'none',
          }}
        >
          <JobDetail
            mutateUserDetail={() => { void mutate(); }}
            job={jobDetail ? { ...jobDetail, ...(jobUserDetail || {}) } : null}
            similarJobs={similarJobs?.length ? similarJobs[0] : null}
            closeModal={closeModal}
            isLoading={isLoadingJobDetail}
            modalPrevNextJobs={{
              prevJob: prevItem,
              nextJob: nextItem,
            }}
            modalNavigateToJob={navigateToJob}
            locale={locale}
          />

          {/* Prev item placeholder */}
          {prevItem && (
            <div className="absolute inset-0 -translate-x-full">
              <JobDetail
                job={null}
                isSkeletonPlaceholder
                closeModal={closeModal}
                jobTitle={prevItem.title}
                locale={locale}
              />
            </div>
          )}

          {/* Next item placeholder */}
          {nextItem && (
            <div className="absolute inset-0 translate-x-full">
              <JobDetail
                job={null}
                isSkeletonPlaceholder
                closeModal={closeModal}
                jobTitle={nextItem.title}
                locale={locale}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
